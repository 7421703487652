<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="
      (value) => $store.commit('layout/set', ['sidebarShow', value])
    "
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <CSidebarNav>
      <CSidebarNavItem name="Dashboard" to="/" icon="cil-speedometer" />

      <CSidebarNavItem
        name="Playlists"
        to="/playlists"
        :exact="false"
        icon="cil-featured-playlist"
      />

      <!-- <CSidebarNavItem name="Devices" to="/devices" icon="cil-devices" /> -->
      <CSidebarNavItem name="FAQ" to="/faq" icon="cil-info" />
      <CSidebarNavItem name="Support" to="/support" icon="cil-phone" />
    </CSidebarNav>

    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="
        $store.commit('layout/set', ['sidebarMinimize', !minimize])
      "
    />
  </CSidebar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheSidebar",
  computed: mapState({
    show: (state) => state.layout.sidebarShow,
    minimize: (state) => state.layout.sidebarMinimize,
  }),
};
</script>