<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit="login">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>

                  <CAlert
                    color="danger"
                    v-for="(error, index) in errors"
                    :key="index"
                  >
                    {{ error }}
                  </CAlert>

                  <CInput
                    v-model="email"
                    placeholder="Email"
                    autocomplete="email"
                    prepend="@"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left mb-3">
                      <CSpinner color="info" v-if="loading" />
                      <CButton
                        type="submit"
                        color="primary"
                        v-if="!loading"
                        class="px-4"
                        >Login</CButton
                      >
                    </CCol>

                    <CCol col="6" class="text-right d-none d-md-block">
                      <CButton
                        type="button"
                        color="link"
                        class="px-0"
                        to="forgot"
                        >Forgot password?</CButton
                      >
                    </CCol>

                    <CCol col="12" class="text-center d-block d-md-none">
                      <CButton
                        type="button"
                        color="link"
                        class="px-0"
                        block
                        to="forgot"
                        >Forgot password?</CButton
                      >
                      <CButton
                        type="button"
                        color="link"
                        to="registration"
                        block
                        >Register now!</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <CButton
                  color="light"
                  to="registration"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
// import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      email: null,
      password: null,
      errors: [],
      success: false,
      loading: false,
    };
  },
  //   computed: mapState({
  //     user: (state) => state.auth.user,
  //     ...mapGetters({
  //       authStatus: "auth/authStatus",
  //       isLoggedIn: "auth/isLoggedIn",
  //     }),
  //   }),
  methods: {
    login(e) {
      this.loading = true;
      this.errors = [];

      if (!this.email) {
        this.success = false;
        this.loading = false;
        this.errors.push("Email can't be blank");
      }

      if (!this.password) {
        this.success = false;
        this.loading = false;
        this.errors.push("Password can't be blank");
      }

      if (!this.errors.length) {
        this.$store
          .dispatch("auth/login", {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.success = true;
            this.loading = false;
            this.$router.push("dashboard");
          })
          .catch((error) => {
            this.success = false;
            if (error.response.data.errors) {
              this.errors = error.response.data.errors;
            }
            this.loading = false;
          });
      }
      e.preventDefault();
    },
  },
};
</script>
