import axios from "axios"

const api_root = process.env.VUE_APP_API_URL

export default {

    login(email, password) {
        const url = `${api_root}/users/login`;
        return axios(url, {
            method: "post", withCredentials: true, data: {
                email: email,
                password: password
            }
        })
    },

    registration(email, password) {
        const url = `${api_root}/users/signup`;
        return axios(url, {
            method: "post", withCredentials: true, data: {
                user: {
                    email: email,
                    password: password
                }
            }
        })
    },

    getProfile() {
        const url = `${api_root}/user`;
        return axios.get(url, {
            // withCredentials: true,
            // headers: {
            //     'Authorization': `Bearer ${token}`
            // }
        })

    },


}