import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Login from '../views/public/Login.vue'
// import PublicLayout from '../views/public/Layout.vue'
import PrivateLayout from '../views/private/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import( /* webpackChunkName: "registration" */ '../views/public/Registration.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import( /* webpackChunkName: "forgot" */ '../views/public/Forgot.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import( /* webpackChunkName: "forgot" */ '../views/public/Privacy.vue')
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    component: PrivateLayout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import( /* webpackChunkName: "dashboard" */ '../views/private/Dashboard.vue')
      },
      {
        path: 'playlists',
        meta: {
          label: 'Playlists'
        },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'Playlists List',
            component: () => import( /* webpackChunkName: "playlists" */ '../views/private/Playlists.vue'),
          },
          {
            path: 'new',
            name: 'New Playlist',
            component: () => import( /* webpackChunkName: "new-playlist" */ '../views/private/playlists/New.vue')
          },
          {
            path: ':id',
            name: 'Show Playlist',
            component: () => import( /* webpackChunkName: "show-playlist" */ '../views/private/playlists/Show.vue')
          },
        ]
      },
      {
        path: 'devices',
        name: 'Devices',
        component: () => import( /* webpackChunkName: "devices" */ '../views/private/Devices.vue')
      },
      {
        path: 'faq',
        name: 'Faq',
        component: () => import( /* webpackChunkName: "Faq" */ '../views/private/Faq.vue')
      },
      {
        path: 'support',
        name: 'Support',
        component: () => import( /* webpackChunkName: "Support" */ '../views/private/Support.vue')
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import( /* webpackChunkName: "settings" */ '../views/private/Settings.vue')
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    store.dispatch("auth/getProfile").then(
      () => {
        if (store.getters['auth/isLoggedIn']) {
          next()
          return
        }
        next('/login')
      },
      () => {
        next('/login')
      }
    );
  } else {
    next()
  }
})


export default router