import axios from "axios"

const api_root = process.env.VUE_APP_API_URL

export default {

    getProviders() {
        return axios.get(`${api_root}/v1/providers`)
    },


}