import providers from '@/api/providers'

// initial state
const state = () => ({
    list: [],
    loading: false,
})

// getters
const getters = {
    providers: state => state.list,
}

// actions
const actions = {

    getProviders({ commit }) {
        commit("showLoading")
        providers.getProviders().then(data => {
            commit("setList", data.data)
            commit("hideLoading")
        }).catch(() => {
            commit("hideLoading")
        })
    },

}

// mutations
const mutations = {

    showLoading(state) {
        state.loading = true
    },

    hideLoading(state) {
        state.loading = false
    },

    setList(state, providers) {
        state.list = providers
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}