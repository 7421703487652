<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {},
  created() {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/style";
</style>
