import auth from '../../api/auth'
import Cookies from 'js-cookie';

// initial state
const state = () => ({
    status: '',
    token: Cookies.get('auth_token') || '',
    user: {}
})

// getters
const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    token: state => state.token
}

// actions
const actions = {

    login({ commit }, { email, password }) {
        return new Promise((resolve, reject) => {
            commit("userRequest")
            auth.login(email, password).then(data => {
                Cookies.set('auth_token', data.data.user.token)
                commit("userSuccess", { token: data.data.user.token, user: data.data.user })
                resolve()
            }).catch((error) => {
                Cookies.remove('auth_token')
                commit('userError')
                reject(error)
            })
        })
    },

    getProfile({ commit, state }) {
        return new Promise((resolve, reject) => {
            commit("userRequest")
            if (state.token) {
                auth.getProfile().then(data => {
                    commit("userSuccess", { token: data.data.user.token, user: data.data.user })
                    resolve()
                }).catch(() => {
                    Cookies.remove('auth_token')
                    commit('userError')
                    reject()
                })
            } else {
                commit('userError')
                reject()
            }
        })
    },

    logout({ commit }) {
        Cookies.remove('auth_token')
        commit("logout")
    }
}

// mutations
const mutations = {
    userRequest(state) {
        state.status = 'loading'
    },
    userSuccess(state, { token, user }) {
        state.status = 'success'
        state.token = token
        state.user = user
    },

    userError(state) {
        state.status = 'error'
    },

    logout(state) {
        state.status = ''
        state.token = ''
        state.user = {}
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}